@import '../../styles/variables';
@import '../../styles/styles';

.container {
  display: inline-block;
  cursor: pointer;
  line-height: $navbarHeight;
}

.bar1, .bar2, .bar3 {
  width: 35px;
  height: 4px;
  background-color: #333;
  margin: 6px 0;
  transition: 0.4s;
  background-color: white;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}

.change .bar2 {opacity: 0;}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}