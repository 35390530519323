@import '../../styles/variables';
@import '../../styles/styles';


.heroImage {
  background-image: url('../../assets/images/river-avon-sea-mills.jpg');
}


.homePage {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: $borderRadius;
  background-color: black;
  opacity: 0.8;
  border: $borderColor solid 1px;
  padding-bottom: 10px;
  
  h1, h2, h3, h4 {
    padding: 15px 10px;
  } 
}

@media screen and (min-width: 770px) {
  .homePage {
    min-width: 350px;
  }
}

@media screen and (max-width: 769px) {
  
  .homePage {
    width: 90vw;
  }
  
}