@import '../../styles/variables';
@import '../../styles/styles';


.heroImage {
  background-image: url('../../assets/images/trym-in-the-mist.jpg');
}

.homePage {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: $borderRadius;
  background-color: black;
  opacity: 0.8;
  border: $borderColor solid 1px;
  padding-bottom: 10px;
  
  h2, h3, h4 {
    padding: 5px 10px;
    text-align: left;
    
  } 
  p {
    text-align: left;
    padding: 5px 10px
  }
  li {
    text-align: left;
  }
  ul {
    display: inline-block;
  }
}

.aboutTitle {
  margin: auto;
}


  
@media screen and (min-width: 770px) {
  .homePage {
    min-width: 350px;
  }
}

@media screen and (max-width: 769px) {
  
  .homePage {
    width: 90vw;
  }
  
}