@import '../../styles/variables';
@import '../../styles/styles';


.heroImage {
  background-image: url('../../assets/images/sea-mills-and-clouds.jpg');
  height: 100vh;
  background-size: cover;
  z-index: -1;
  background-position: center;
}

.skillsGrid {
  display: grid;
  grid-template-columns: 100px 100px 100px 100px;
  grid-template-rows: 100px 100px 100px;
}

.skill {
  // background-color: red;
  margin: 5px;
}

.skillsPage {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: $borderRadius;
  background-color: black;
  opacity: 0.8;
  border: #084b54 solid 2px;
  padding-bottom: 10px;
    
  h2, h3, h4 {
    padding: 5px 10px;
    text-align: left;
    
  } 
  p {
    text-align: left;
    padding: 0 10px
  }
}

.aboutTitle {
  margin: auto;
  margin-bottom: 15px;
}

.icon {
  font-size: 3em;
  color: white;
  :hover {
    font-size: 1.5em;
  }
}