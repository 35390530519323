.navbarSpacer {
  height: $navbarHeight;
}

h1, h2, h3, h4, p {
  margin: 0;
}
.heroImage {
  height: 100vh;
  background-size: cover;
  z-index: -1;
  background-position: center;
}

.vertical_center {
  // margin-bottom: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -40%);
  transform: translate(-50%, -40%);
}

@media screen and (max-width: 769px) {
  
  .navbarSpacer {
    height: 0;
  }
  
}