@import '../../styles/variables';
@import '../../styles/styles';

.menuButton {
  position: relative;
  background-color: blue;
  z-index: 2;
}

.navbar {
  position: absolute;
  display: flex;
  width: 100vw;
  flex-direction: row;
  justify-content: space-around;
  height: $navbarHeight;
  background-color: $navbarBackColour ;
 
  a {
    color: white;
    width: 80px;
    font-size: 20px;
    height: $navbarHeight;
    line-height: $navbarHeight;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
  }
}


@media screen and (max-width: 768px) {

  .menuButton {
    position: relative;
    z-index: 1;
    background-color: red;
    width: 80px;
  }
  .navbar {
    flex-direction: column;
    height: 100vh;
    width: max-content;
    justify-content: flex-start;
    padding: 0 5px;

    a:hover {
      text-decoration: underline;
    }
  }
}